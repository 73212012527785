import { defineStore } from 'pinia';

interface IGameCollectionStoreState {
  cachedFeatures: string[];
}

export const useGameFeaturesStore = defineStore('gameFeaturesStore', {
  state: (): IGameCollectionStoreState => ({
    cachedFeatures: [],
  }),

  getters: {
    featuresWithIdentity({ cachedFeatures = [] }) {
      return cachedFeatures.map((feature) => {
        return {
          name: feature,
          identity: encodeURIComponent(feature)
        }
      })
    }
  },

  actions: {
    async getFeatures(): Promise<string[]> {
      const { getGameFeatures } = useCoreGamesApi();

      if (this.cachedFeatures.length) {
        return this.cachedFeatures;
      }

      try {
        const { features } = await getGameFeatures();
        this.cachedFeatures = features;
      } catch (error) {
        console.error('Error loading features:', error);
        return [];
      }

      return this.cachedFeatures;
    },
  },
});
