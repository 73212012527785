<template>
  <div
    class="filterable-check-box"
    :class="customClass"
  >
    <div class="filterable-check-box__search">
      <form-input-search
        v-model:value="searchValue"
        :placeholder="getContent(popupsData, defaultLocalePopupsData, 'gamesFilter.searchPlaceholder')"
      />
    </div>

    <template v-if="filteredOptions.length">
      <div class="filterable-check-box__options">
        <div
          class="filterable-check-box__option"
          v-for="item in filteredOptions"
          :key="item[identifierKey]"
        >
          <form-input-checkbox
            :modelValue="isOptionSelected(item[identifierKey])"
            @change="toggleOptionSelection(item[identifierKey])"
          >
            {{ item[labelKey] }}
          </form-input-checkbox>
        </div>
      </div>

      <div
        v-if="!hideActionBtn"
        class="filterable-check-box__btn"
      >
        <button-base
          class="btn-apply"
          type="primary"
          size="sm"
          @click="onSave"
        >
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.save') }}
        </button-base>
      </div>
    </template>

    <atomic-empty
      v-else
      :title="getContent(gamesContent?.currentLocaleData, gamesContent?.defaultLocaleData, 'empty.title')"
      :subTitle="getContent(gamesContent?.currentLocaleData, gamesContent?.defaultLocaleData, 'empty.description')"
      variant="not-found"
    />
  </div>
</template>

<script setup lang="ts">
import { localSearch } from '@skeleton/utils/localSearchUtils';
import type { IGamesPage } from "~/types";

const props = withDefaults(
  defineProps<{
    options: any[];
    selectedOptions: string[];
    identifierKey?: string;
    labelKey?: string;
    customClass?: string;
    hideActionBtn?: boolean
  }>(),
  {
    options: () => [],
    selectedOptions: () => [],
    identifierKey: 'identity',
    labelKey: 'name',
    customClass: '',
    hideActionBtn: false
  }
);

const emits = defineEmits<{
  (event: 'update:selectedOptions', value: string[]): void;
  (event: 'onSave'): void;
}>();

const contentParams = {
  contentKey: 'gamesPageContent',
  contentRoute: ['pages', 'games'],
  isPage: true,
};

const { getContentData } = useContentLogic<IGamesPage>(contentParams);
const { data: gamesContent } = await useLazyAsyncData(getContentData);

const { getContent } = useProjectMethods();
const { popupsData, defaultLocalePopupsData, layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());

const searchValue = ref<string>('');

const filteredOptions = computed(() => localSearch(props.options, searchValue.value, props.labelKey));

const isOptionSelected = (value: string): boolean => {
  return props.selectedOptions.includes(value);
};

const toggleOptionSelection = (value: string): void => {
  const updatedSelectedOptions = isOptionSelected(value)
    ? props.selectedOptions.filter((item) => item !== value)
    : [...props.selectedOptions, value];

  emits('update:selectedOptions', updatedSelectedOptions);
};

const onSave = (): void => {
  emits('onSave')
}
</script>

<style src="~/assets/styles/components/list/filterable-check-box.scss" lang="scss" />
