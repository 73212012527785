const localSearch = (items: any[], query: string, key: string): any[] => {
  if (!query) {
    return items;
  }

  const lowerCaseQuery = query.toLowerCase();

  return items.filter((item) =>
    item[key]?.toLowerCase().includes(lowerCaseQuery)
  );
};

export {
  localSearch
}
